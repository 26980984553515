<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
			:slot_table_list="['operation','notify_status','is_read','sign_status']" ref="list2" :rowKey="'id'">
			<!-- <template slot="right_btn">
                <a :href="'/manage/crm/outbound_order_export' | export_form" target="_blank">
                    <a-button class="a_btn">导出</a-button>
                </a>
            </template> -->
			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加司机</a-button>
			</template>
			<template slot="type" slot-scope="data">
				<span>{{data.record.type == 1 ? '承运/自提' : '统一配送'}}</span>
			</template>
			<template slot="operation" slot-scope="data">
				<a v-auth="'change'" @click="go_detail(data.record)">编辑</a>
				<a-divider type="vertical" />
				<a @click="Unbind(data.record)">解绑</a>
			</template>
		</TableList>
	</div>
</template>
<script>
	// @ is an alias to /src
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import { onBeforeUnmount, onUnmounted } from 'vue';
	import EditPop from "@/components/EditPop";
	import deactivated from '@/methods/deactivated'
	import {
		get_driver_list,
		driverUnbind
		// outbound_order_export
	} from '@/api/push'
	const columns = [{
			title: "司机名称",
			dataIndex: "chauffeur",
		},
		{
			title: "车牌",
			dataIndex: "truck_license",
		},
		{
			title: "联系电话",
			dataIndex: "chauffeur_mobile",
		},
		{
			title: "归属承运商",
			dataIndex: "merchant_name",
		},
		{
			title: "承运商编号",
			dataIndex: "merchant_id",
		},
		{
			title: "司机类型",
			dataIndex: "type_name",
		},
		{
			title: "注册时间",
			dataIndex: "create_time",
		},
		{
			title: "是否绑定微信",
			dataIndex: "bind_status",
		},
		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];


	export default {
		name: "driver",
		components: {
			Form,
			TableList,
		},
		data() {
			return {
				get_table_list: get_driver_list,
				columns,
				visible: false,
				form_data_seo: {
					...this.$config.form_data_seo,
					list: [

						{
							type: "text",
							name: "keyword",
							title: "关键词",
							placeholder: "请输入司机名称/车牌/电话",
							options: {}
						},
						{
							type: "select",
							name: "is_bind",
							list: [{
									key: 1,
									value: '已绑定'
								},
								{
									key: -1,
									value: '未绑定'
								},
							],
							title: "是否绑定微信",
							options: {}
						},
						{
							type: "select",
							name: "type",
							list: [{
									key: 2,
									value: '统一配送'
								},
								{
									key: 1,
									value: '承运/自提'
								},
							],
							title: "司机类型",
							options: {}
						},
					],
				},
			};
		},
		created() {
			Promise.all([this.$method.get_department()]).then(res => {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "department_id") {
						item.treeData = res[0];
					}

				});
			});

		},
		onUnmounted() {
		},
		activated() {
			this.$refs.list2.get_list();
		},
		deactivated() {
			// window.removeEventListener('popstate', this.handleBack, true);
		},
		mounted() {
			// window.addEventListener('popstate', this.handleBack, true);
		},
		methods: {
			handleBack() {
				if(this.$refs.list2){
					this.$refs.list2.get_list();
				}
			},
			// 解绑
			Unbind(data) {
				driverUnbind({
					data: {
						id: data.id
					},
					info: true
				}).then(res => {
					this.$refs.list2.get_list()
				})
			},
			//查看司机信息
			go_detail(e) {
				this.$keep_route.add(this.$options.name);
				this.$router.push({
					path: '/driver/details',
					query: {
						id: e.id
					}
				})
				// this.form_data.title = "编辑"
				// let data = e
				// this.form_data.list.forEach(item => {
				//     if (item.type == 'upload') {
				//         let val = {};
				//         val[item.name] = {
				//             fileList: [{
				//                 url: data[item.name]
				//             }]
				//         }
				//         item.options.initialValue = [{
				//             uid: data[item.name],
				//             name: data[item.name],
				//             status: 'done',
				//             url: data[item.name]
				//         }];

				//     } else {
				//         item.options.initialValue = data[item.name];
				//     }
				// })
				// this.visible = true
			},
			//仓库产品列表
			go_product(e) {
				this.$router.push({
					path: '/driver/product',
					query: {
						id: e.id
					}
				})

			},
			//添加
			add() {
				this.$keep_route.add(this.$options.name);
				this.$router.push({
					path: '/driver/details',
					query: {
						id: ''
					}
				})
			},
			//导出
			download() {
				outbound_order_export({
					data: {
						...this.$refs.list.seo_data
					}
				}).then(res => {
					let a = document.createElement('a');
					a.href = res.url;
					a.target = '_blank'
					a.click();
				})
			}

		}
	};
</script>